import ReactHlsPlayer from "react-hls-player";
import { TiEyeOutline } from "@react-icons/all-files/ti/TiEyeOutline";
import { useRef, useEffect, useState } from "preact/hooks";
import ChatState from "../components/ChatState";

const Portrait = ({ streamkey, username, description }) => {
  const [showJoin, setShowJoin] = useState(true);
  const [startedMuted, setStartedMuted] = useState(false);
  const playerRef = useRef(null);
  const countRef = useRef(null);
  const screenWidth = window.innerWidth;
  const deduct = screenWidth > 1300 ? 200 : 96;
  const margin = deduct / 2;
  const videoHeight = window.innerHeight - deduct;
  const videoWidth = videoHeight / 2.1666666;

  const handleFullScreen = () => {
    if (playerRef.current.requestFullscreen) {
      playerRef.current.requestFullscreen();
    } else if (playerRef.current.mozRequestFullScreen) {
      playerRef.current.mozRequestFullScreen();
    } else if (playerRef.current.webkitRequestFullscreen) {
      playerRef.current.webkitRequestFullscreen();
    } else if (playerRef.current.msRequestFullscreen) {
      playerRef.current.msRequestFullscreen();
    } else if (playerRef.current.webkitEnterFullScreen) {
      playerRef.current.webkitEnterFullScreen();
    }
  };
  const updateCount = (num) => {
    if (countRef && countRef.current) {
      countRef.current.textContent = num.toString();
    }
  };
  const handleMute = (isMuted) => {
    if (isMuted) {
      //sound plays
      playerRef.current.muted = false;
    } else {
      //sound does not play
      playerRef.current.muted = true;
    }
  };
  const handlePlay = () => {
    if (playerRef && playerRef.current) {
      playerRef.current.play();
      playerRef.current.currentTime = playerRef.current.duration - 4;
      setShowJoin(false);
    }
  };
  useEffect(() => {
    if (playerRef && playerRef.current) {
      let promise = playerRef.current.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            // Autoplay started!
            setShowJoin(false);
            console.log("autoplay started.");
          })
          .catch(() => {
            // Autoplay not allowed!
            // Mute video and try to play again
            playerRef.current.muted = true;
            let mutedPromise = playerRef.current.play();
            if (promise !== undefined) {
              mutedPromise
                .then(() => {
                  //started!
                  console.log("muted started!");
                  setStartedMuted(true);
                  setShowJoin(false);
                })
                .catch(() => {
                  //all done try.
                  playerRef.current.muted = false;
                });
            }

            // Show something in the UI that the video is muted
          });
      }

      //https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8
      //https://nvge.com/live_stream/${streamkey}.m3u8
    }
  }, []);
  return (
    <div className="videoHolder">
      <div className="videoHolderInner">
        {showJoin && (
          <div className="videoMiddle" onClick={handlePlay}>
            <div className="videoMiddleInner">
              <button>JOIN STREAM</button>
            </div>
          </div>
        )}
        <div className="justVideo fullScreen">
          <ReactHlsPlayer
            playerRef={playerRef}
            src={`https://nvge.com/live_stream/${streamkey}.m3u8`}
            controls={false}
            playsInline={true}
            key="video-player"
            autoPlay={true}
            style={
              screenWidth >= 968 && videoHeight > 0
                ? { width: videoWidth, height: videoHeight, marginTop: margin }
                : null
            }
            hlsConfig={{
              maxSeekHole: 2,
              seekHoleNudgeDuration: 0.5,
              maxBufferLength: 30,
              maxBufferSize: 6e7,
              fragLoadingTimeOut: 3900,
              fragLoadingMaxRetry: 2,
              liveSyncDuration: 1,
              liveMaxLatencyDuration: 12,
            }}
          />
        </div>
        <div>
          <div className="videoTop">
            <div className="avatar">
              <a href="https://nats.netvideogirls.com/track/Mi4xLjEuMS4xMDcuMC4wLjAuMA">
                <img
                  src="https://static.addfriends.com/images/sites/mike.jpg"
                  alt="Mike"
                />
              </a>
              <span>{description}</span>
            </div>

            <div className="info">
              <div className="live">LIVE</div>
              <div className="watching">
                <TiEyeOutline />
                <span ref={countRef}>1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!showJoin && (
        <ChatState
          username={username}
          handleMute={handleMute}
          handleFullScreen={handleFullScreen}
          startedMuted={startedMuted}
          updateCount={updateCount}
        />
      )}
    </div>
  );
};

export default Portrait;
