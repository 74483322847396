import VideoActions from "../../videoactions";
import Chat from "../Chat";
import style from "./style.css";
import { useState } from "preact/hooks";
const ChatState = ({
  username,
  handleMute,
  startedMuted,
  handleFullScreen,
  updateCount,
}) => {
  const [fullChat, setFullChat] = useState(false);
  const [nag, setNag] = useState(true);
  const handleUnNag = () => {
    setNag(false);
    handleMute(true);
  };
  return (
    <div
      className={
        fullChat ? `${style.chatState} ${style.fullchat}` : style.chatState
      }
    >
      <VideoActions
        muteBTN={handleMute}
        handleFullScreen={handleFullScreen}
        isMuted={startedMuted}
        setFullChat={setFullChat}
        fullChat={fullChat}
        theather={false}
      />
      <div className={style.rightPanel}>
        <Chat
          username={username}
          updateCount={updateCount}
          fullChat={fullChat}
        />
      </div>
      {startedMuted && nag && (
        <div className={style.muted}>
          <div className={style.mutedInner}>
            <p>Video started muted, would you like to unmute?</p>
            <p>
              <span className={style.green} onClick={handleUnNag}>
                Yes
              </span>{" "}
              /{" "}
              <span className={style.red} onClick={() => setNag(false)}>
                No
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatState;
