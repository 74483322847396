import ChatBoxL from "../ChatBoxL";
import style from "./style.css";
import { useState, useEffect, useRef } from "preact/hooks";
// const temp = [
//   {
//     name: "Michael",
//     msg:
//       "999Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "Mike",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   { name: "Fred", msg: "Lorem ipsum dolor, sit amet consectetur" },
//   {
//     name: "Michael",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit temporibus quia, laboriosam pariatur maxime deleniti asperiores eveniet cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!",
//   },
//   {
//     name: "James",
//     msg:
//       "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt impedit quisquam, voluptatem quam quis reprehenderit",
//   },
//   {
//     name: "Mike",
//     msg: "111Lorem ipsum dolor, sit amet consectetur",
//     special: "mike",
//   },
// ];
const ChatL = ({ username, updateCount, fullChat }) => {
  const [chat, setChat] = useState([]);
  const [pause, setPause] = useState(false);
  const [connected, setConnected] = useState(false);
  const scrollRef = useRef(null);
  const chatRef = useRef(null);
  const handleSend = (text) => {
    chatRef.current.send(text);
    setChat((prev) => [...prev, { name: "You", msg: text, special: "you" }]);
  };
  // useEffect(() => {
  //   let width = 0;
  //   if (window.outerHeight) {
  //     width = window.outerWidth;
  //   } else {
  //     width = document.body.clientWidth;
  //   }

  //   if (width <= 968) {
  //     setPause(true);
  //   }
  // }, []);
  useEffect(() => {
    if (username !== "" && chatRef.current === null) {
      //const wsUri = `wss://nvge.com/ws/?name=${username}`;
      const wsUri = `wss://nvge.com/ws/?name=${username}`;

      chatRef.current = new WebSocket(wsUri);
      chatRef.current.onopen = function () {
        //Connected to chat maybe display chat I suppose?
        setConnected(true);
      };
      chatRef.current.onmessage = function (e) {
        //here are my messages...
        const splitMsg = e.data.split("|");
        if (splitMsg.length === 2) {
          let special = "";
          if (splitMsg[0] === "Mike") {
            special = "mike";
          } else if (splitMsg[0] === "system") {
            special = "system";
          }
          if (splitMsg[0] === "count") {
            //first count is 0 WTF.
            const runningCount = parseInt(splitMsg[1], 10);
            if (runningCount <= 0) {
              updateCount(1);
            } else {
              updateCount(runningCount);
            }

            return;
          }
          setChat((prev) => [
            ...prev,
            { name: splitMsg[0], msg: splitMsg[1], special },
          ]);
        } else if (splitMsg.length === 1) {
          setChat((prev) => [
            ...prev,
            { name: "Anon", msg: e.data, special: "" },
          ]);
        }
      };
      chatRef.current.onclose = function () {
        //closing it down here..
        chatRef.current = null;
      };
    }
  }, [username, updateCount]);
  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [fullChat]);
  useEffect(() => {
    if (scrollRef && scrollRef.current && !pause) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chat, pause]);
  return (
    <div className={style.chatContainer}>
      <div
        className={
          fullChat ? `${style.chatWindow} ${style.full}` : style.chatWindow
        }
      >
        <div className={style.chatWindowInner} ref={scrollRef}>
          {chat.map((t, index) => {
            return (
              <div
                className={
                  t.special === "you"
                    ? `${style.chatItem} ${style.you}`
                    : t.special === "mike"
                    ? `${style.chatItem} ${style.mike}`
                    : t.special === "system"
                    ? `${style.chatItem} ${style.system}`
                    : style.chatItem
                }
                key={`chatItemL-${index}`}
              >
                {t.special !== "system" && <h4>{t.name}</h4>}
                <p>{t.msg}</p>
              </div>
            );
          })}
          <div className={style.scroll} />
        </div>
      </div>
      <ChatBoxL
        setPause={setPause}
        pause={pause}
        handleSend={handleSend}
        connected={connected}
      />
    </div>
  );
};

export default ChatL;
