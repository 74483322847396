import style from "./style.css";
import { useState } from "preact/hooks";
import { GrUpdate } from "@react-icons/all-files/gr/GrUpdate";
import { GiPauseButton } from "@react-icons/all-files/gi/GiPauseButton";

const ChatBoxL = ({ setPause, pause, handleSend, connected }) => {
  const [msg, setMsg] = useState("");
  const handleMsg = (text) => {
    setMsg("");
    handleSend(text);
  };
  return (
    <div className={style.chatBox}>
      <input
        type="text"
        placeholder={connected ? "Type Here" : "Not Connected"}
        disabled={!connected}
        value={msg}
        onChange={(e) => setMsg(e.target.value.trim())}
        className={style.chatInput}
        onKeyDown={(e) => {
          if (e.key === "Enter" && msg !== "") {
            handleMsg(msg);
          }
        }}
      />
      <div className={style.pause} onClick={() => setPause((prev) => !prev)}>
        {pause ? <GrUpdate /> : <GiPauseButton />}
      </div>
      {!connected && (
        <div className={style.connectingHolder}>
          <div class={style.connecting}>Connecting</div>
        </div>
      )}
    </div>
  );
};

export default ChatBoxL;
