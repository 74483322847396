import { useState, useEffect } from "preact/hooks";
import { IoVolumeMuteOutline } from "@react-icons/all-files/io5/IoVolumeMuteOutline";
import { IoVolumeMediumOutline } from "@react-icons/all-files/io5/IoVolumeMediumOutline";
import { AiOutlineExpand } from "@react-icons/all-files/ai/AiOutlineExpand";
import { AiOutlineCloseSquare } from "@react-icons/all-files/ai/AiOutlineCloseSquare";
import { FaTheaterMasks } from "@react-icons/all-files/fa/FaTheaterMasks";
import { BsChatDots } from "@react-icons/all-files/bs/BsChatDots";
import { ImLink } from "@react-icons/all-files/im/ImLink";
import { BsChatFill } from "@react-icons/all-files/bs/BsChatFill";

const VideoActions = ({
  muteBTN,
  handleFullScreen,
  isMuted,
  setFullChat,
  fullChat,
  theather,
  handleTheater,
}) => {
  const [muted, setMuted] = useState(false);
  const [isTheater, setIsTheater] = useState(false);
  const handleLink = () => {
    let g = window.open(
      "https://nats.netvideogirls.com/track/Mi4xLjEuMS4xMTIuMC4wLjAuMA",
      "_blank"
    );
    g.focus();
  };
  useEffect(() => {
    setMuted(isMuted);
  }, [isMuted]);

  const handleChat = () => {
    //handle
    setFullChat((prev) => !prev);
  };
  const handleMute = () => {
    if (muted) {
      setMuted(false);
      muteBTN(true);
    } else {
      setMuted(true);
      muteBTN(false);
    }
  };
  return (
    <div>
      {/* <div className="chatWindow">
        <div className="chatItem">
          <h4>Michael</h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt
            impedit quisquam, voluptatem quam quis reprehenderit temporibus
            quia, laboriosam pariatur maxime deleniti asperiores eveniet
            cupiditate rem blanditiis voluptatibus, accusantium deserunt. Velit!
          </p>
        </div>
        <div className="chatItem">
          <h4>James</h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt
            impedit quisquam, voluptatem quam quis reprehenderit
          </p>
        </div>
        <div className="chatItem">
          <h4>Fred</h4>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
        </div>
      </div> */}
      <div className="videoBottom">
        {/* <div className="comment">
          <input type="text" placeholder="Type comment" value="" onChange={ }/>
        </div> */}
        <div className="actions">
          <div className="actionItem" onClick={handleFullScreen}>
            <AiOutlineExpand className="icon" />
            Full
          </div>
          {theather && (
            <div
              className="actionItem"
              id="movie"
              onClick={() => {
                handleTheater(!isTheater);
                setIsTheater((prev) => !prev);
              }}
            >
              {isTheater ? (
                <AiOutlineCloseSquare className="icon" />
              ) : (
                <FaTheaterMasks className="icon" />
              )}
              {isTheater ? "Close" : "Theater"}
            </div>
          )}

          {muted ? (
            <div className="actionItem" onClick={handleMute}>
              <IoVolumeMediumOutline className="icon" />
              Turn On
            </div>
          ) : (
            <div className="actionItem" onClick={handleMute}>
              <IoVolumeMuteOutline className="icon" />
              Turn Off
            </div>
          )}
          <div className="desktopMobile actionItem" onClick={handleLink}>
            <ImLink className="icon" />
            NVG
          </div>
          <div
            className={
              fullChat
                ? "mobileOnly actionItem disabled"
                : "mobileOnly actionItem"
            }
            onClick={handleChat}
          >
            {fullChat ? (
              <BsChatFill className="icon" />
            ) : (
              <BsChatDots className="icon" />
            )}
            {fullChat ? "Close" : "Chat"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoActions;
