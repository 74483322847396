import "./style";
import { Router } from "preact-router";
import Portrait from "./portrait";
import LandScape from "./landscape";
import { useState } from "preact/hooks";
import Login from "./components/Login";
import Raw from "./raw";

const Index = ({ streamkey }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [offline, setOffline] = useState(false);
  const [username, setUsername] = useState("");
  const [orientation, setOrientation] = useState("");
  const [description, setDescription] = useState("");
  const handleLogin = (name) => {
    let check_stream_key = streamkey;
    if (check_stream_key === "") {
      check_stream_key = "index";
    }
    fetch(`https://nvge.com/stream_info/${check_stream_key}`)
      .then((res) => {
        if (res.status === 500) {
          return 1;
        }
        return res.json();
      })
      .then((res) => {
        //handle
        if (typeof res === "number") {
          setOffline(true);
          return;
        }
        if (res.is_active === "n") {
          setOffline(true);
          return;
        }
        setOrientation(res.stream_type);
        setDescription(res.description);
        setUsername(name);
        setLoggedIn(true);
      });
  };

  if (!loggedIn) {
    return <Login handleLogin={handleLogin} offline={offline} />;
  }
  if (orientation === "y") {
    return (
      <Portrait
        streamkey={streamkey === "" ? "index" : streamkey}
        username={username}
        description={description}
      />
    );
  } else if (orientation === "x") {
    return (
      <LandScape
        streamkey={streamkey === "" ? "index" : streamkey}
        username={username}
        description={description}
      />
    );
  }

  return <Raw streamkey={streamkey === "" ? "index" : streamkey} />;
};

export default function App() {
  return (
    <Router>
      <Index path="/:streamkey?" />
    </Router>
  );
}
