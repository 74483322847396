import style from "./style.css";
import { useState } from "preact/hooks";
import { BiErrorAlt } from "@react-icons/all-files/bi/BiErrorAlt";

const Login = ({ handleLogin, offline }) => {
  const [local, setLocal] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const flashError = (msg) => {
    setErrorMsg(msg);
    setTimeout(() => {
      setErrorMsg("");
    }, 4000);
  };
  const handleConnect = () => {
    if (loading) return;
    const format = local.toLowerCase().trim();
    if (format === "") {
      //return error
      flashError("please enter in a username!");
      return;
    }
    const bannedUsernames = ["chris", "mike", "null", "you"];
    if (bannedUsernames.includes(format)) {
      //return error
      flashError(
        "This username is not allowed sorry.  Pick something different."
      );
      return;
    }

    if (format.length < 4) {
      //return error
      flashError("Your username needs to be AT LEAST 4 characters long.");
      return;
    }

    if (format.length > 25) {
      //return error
      flashError("Your username needs to be LESS THAN 25 characters.");
      return;
    }
    setLoading(true);
    const finalName = local.trim();
    fetch(`https://nvge.com/check_username/${finalName}`).then((res) => {
      if (res.status === 401 || res.status === 500) {
        setLoading(false);
        flashError("Username is taken, please try something else.");
        return;
      }
      if (res.status === 502) {
        setLoading(false);
        flashError("No live show is going on right now.");
        return;
      }
      handleLogin(finalName);
    });
  };
  return (
    <div className={style.container}>
      <div className={style.containerInner}>
        <div className={style.logo}>
          <span>NVGe</span>xperiences
        </div>
        <div className={style.wMsg}>
          <h2>Welcome!</h2>
          <p>a community of members and fans of NVG testing new experiences</p>
        </div>
        <div className={style.form}>
          {errorMsg !== "" && (
            <div className={style.formError}>
              <BiErrorAlt />
              {errorMsg}
            </div>
          )}
          {offline && (
            <div className={style.formError}>
              <BiErrorAlt />
              There is not live show going right now please check back later!
            </div>
          )}

          <div className={style.inputItem}>
            <input
              type="text"
              placeholder="Username*"
              autoComplete="off"
              value={local}
              onChange={(e) => setLocal(e.target.value)}
              autoFocus
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleConnect();
                }
              }}
            />
          </div>
          {/* <div className={style.inputItem}>
            <input type="password" placeholder="Password" autoComplete="off" />
          </div> */}
          <button className={style.signIn} onClick={handleConnect}>
            {loading ? (
              <div className={style.loadHolder}>
                <div class={style.loader}>Loading...</div>
              </div>
            ) : (
              "Connect"
            )}
          </button>
        </div>
        <div className={style.noPass}>
          * your username will be displayed to others. <strong>AT LEAST</strong>{" "}
          4 characters login.
        </div>
      </div>
    </div>
  );
};

export default Login;
