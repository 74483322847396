import ReactHlsPlayer from "react-hls-player";
import { useRef, useEffect } from "preact/hooks";
const Raw = ({ streamkey }) => {
  let playerRef = useRef(null);
  useEffect(() => {
    if (playerRef && playerRef.current) {
      let promise = playerRef.current.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            // Autoplay started!
            console.log("autoplay with sound started.");
          })
          .catch(() => {
            // Autoplay not allowed!
            // Mute video and try to play again
            playerRef.current.muted = true;
            let mutedPromise = playerRef.current.play();
            if (promise !== undefined) {
              mutedPromise
                .then(() => {
                  //started!
                  console.log("muted started!");
                })
                .catch(() => {
                  //all done try.
                  playerRef.current.muted = false;
                });
            }

            // Show something in the UI that the video is muted
          });
      }
      //https://nvge.com/live_stream/${streamkey}.m3u8
      //https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8
    }
  }, []);
  return (
    <div className="rawVideo">
      <ReactHlsPlayer
        playerRef={playerRef}
        src={`https://nvge.com/live_stream/${streamkey}.m3u8`}
        controls={false}
        playsInline={true}
        key="video-player"
        autoPlay={true}
        hlsConfig={{
          maxSeekHole: 2,
          seekHoleNudgeDuration: 0.5,
          maxBufferLength: 30,
          maxBufferSize: 6e7,
          fragLoadingTimeOut: 3900,
          fragLoadingMaxRetry: 2,
          liveSyncDuration: 1,
          liveMaxLatencyDuration: 12,
        }}
      />
    </div>
  );
};

export default Raw;
